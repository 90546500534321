
import {
  defineComponent, Ref, ref, toRef, computed, watch, onUnmounted
} from 'vue';
import throttle from 'lodash/throttle';

import ShirtIcon from '@/assets/icons/shirt.svg';

import { ArmorLevel } from '@/types/armor';
import { itemSprites, itemNames } from '@/items';
import store from '@/store';
import sassVars from '@/assets/styles/_variables.scss';


export default defineComponent({
  name: 'TheArmorInfo',
  components: { ShirtIcon },
  setup() {
    return {
      selected: toRef(store.state, 'selected'),
      ...useArmor(), ...useFolding()
    };
  }
});


/**
 * Handles user interaction with the currently selected armor: updates the
 * item-list, levels up and down the armor, et cetera.
 */
function useArmor() {
  const setLevel = (i: ArmorLevel): void => store.setSelectedLevel(i);

  const increase = (): void => {
    if ((store.state.selected?.level ?? NaN) < 4)
      store.setSelectedLevel(store.state.selected!.level + 1 as ArmorLevel);
  }

  const decrease = (): void => {
    if ((store.state.selected?.level ?? NaN) > 0)
      store.setSelectedLevel(store.state.selected!.level - 1 as ArmorLevel);
  }

  const isCompleted = computed(() => (store.state.selected?.level ?? NaN) == 4);

  return { setLevel, increase, decrease, isCompleted, itemSprites, itemNames };
}


/**
 * Handles checking if the current window is in mobile or not
 */
function useMobile() {
  const query = window.matchMedia(`(max-width: ${sassVars.break_mobile})`);
  const isMobile = ref(query.matches);

  const onQueryChange = () => isMobile.value = query.matches;

  query.addEventListener('change', onQueryChange);
  onUnmounted(() => query.removeEventListener('change', onQueryChange));

  return { isMobile };
}


/**
 * Handles listening to changing styles when the user scrolls on mobile
 * @param {() => void} options.hitTop The function to run when the user scrolls
 * to the top of the page
 * @param {() => void} options.down The function to run when the user scrolls
 * down
 * @param {() => void} options.up The function to run when the user scrolls up
 * @param {Ref<boolean>[]} options.onlyWhen Do not trigger the scroll listeners
 * unless all of these conditions are met.
 */
function useScroll(options: {
  hitTop: () => void,
  down: () => void,
  up: () => void,
  onlyWhen?: Ref<boolean>[]
}) {
  /**
   * Wrapper for the various different properties to grab scroll-height from,
   * depending on browser/support.
   * @returns The current scroll-position of the document.
   */
  const getScroll = (): number => {
    return document.documentElement.scrollTop ||
      document.body.scrollTop ||
      window.scrollY;
  }

  const savedScrollPos = ref(getScroll());   // The last pos the user stopped

  const canDoScroll = (): boolean => {
    // If there are no options, they can always scroll.
    if (options.onlyWhen === undefined) return true;
    // Otherwise, check if all are true.
    else return options.onlyWhen.every(ref => ref.value);
  }

  const onScroll = throttle((): void => {
    const { body, documentElement } = document;

    const oldScrollPos = savedScrollPos.value;
    const newScrollPos = savedScrollPos.value = getScroll();

    // The Y position of the top of the screen when scrolled down all the way
    const lowestTop = body.scrollHeight - window.innerHeight;
    // :root's current font-size property at whatever the current breakpoint is
    const currentRem = parseFloat(getComputedStyle(documentElement).fontSize);

    if (!canDoScroll()) return;

    // If they scrolled down
    if (newScrollPos > 0 && newScrollPos > oldScrollPos) {
      // If the difference was a big enough scroll to warrant being called a
      // "down" scroll
      if (Math.abs(newScrollPos - oldScrollPos) > 5 * currentRem)
        options.down();
    }

    // If they scrolled to within the top 10.25 rem
    else if (newScrollPos <= 10.25 * currentRem) {
      options.hitTop();
    }

    // If they didn't scroll up (went down), but they *also* aren't below the
    // page (they can be below the page when mobile browsers do that bouncy
    // effect when you try and scroll off the bottom)
    else if (newScrollPos <= lowestTop) {
      // Check if the difference was big enough ( ↓ -- requires larger force
      // than going "down" did) to be called "up" ↓
      if (Math.abs(newScrollPos - oldScrollPos) > 6 * currentRem)
        options.up();
    }

  }, 100, {
    leading: true,
    trailing: true
  });

  window.addEventListener('scroll', onScroll);
  onUnmounted(() => window.removeEventListener('scroll', onScroll));
}


function useFolding() {

  const { isMobile } = useMobile();

  const isFolded = ref(false);

  // See @note below explaining why this is needed
  const chromiumFoldEnabled = ref(true);

  const root = ref<HTMLDivElement>();              // Root <div> of TheArmorInfo
  const header = ref<HTMLHeadingElement>();        // Either of the two headings

  const foldedRootStyles = ref<{ top?: string }>({ });
  const foldedHeaderStyles = ref<{ transform?: string }>({ });

  const unfold = () => {
    isFolded.value = false;
    foldedRootStyles.value = { };
    foldedHeaderStyles.value = { };
    header.value?.blur();
  }

  // Unfold and clear styles if they ever leave mobile view
  watch(isMobile, newValue => { if (!newValue) unfold(); });

  watch(toRef(store.state, 'selected'), (_, oldValue) => {
    if (isMobile.value) {
      unfold();

      if (oldValue === null) {

        /**
         * @note
         *
         * When selecting a new armor, the size of the root <div> changes.
         * Because it's up at the top, this means that the user's browser can
         * either let the document slide down in the viewport (the bigger header
         * shunts the rest down), or it can accommodate for this by
         * automatically scrolling the viewport down to keep the element the
         * user was looking at in place.
         *
         * The latter is what Chromium browsers do. However, since we're
         * listening to the 'scroll' event, and folding when the user scrolls
         * down far enough, this will trigger *another* fold, and subsequently
         * re-hide whatever the user just tried to show.
         *
         * @see {@link https://stackoverflow.com/a/63460737/10549827}
         *
         * We fix this by:
         * - unfolding as normal
         * - disable unfolding
         * - wait for Chromium to move the viewport
         * - re-enable unfolding
         *
         * Since Chrome 86, this requires waiting for two animation-frames. I'm
         * not sure why.
         */

        chromiumFoldEnabled.value = false;
        window.requestAnimationFrame(() => {
          window.requestAnimationFrame(() => {
            chromiumFoldEnabled.value = true;
          });
        });
      }
    }
  });

  useScroll({
    onlyWhen: [ isMobile, chromiumFoldEnabled ],
    hitTop: unfold,
    up: unfold,
    down: () => {
      // Height of root <div#armor-info>
      const rHeight = (root.value?.getBoundingClientRect().height ?? 0);
      // Height of the header <h2>
      const hHeight = (header.value?.getBoundingClientRect().height ?? 0);

      // Offset of header <h2> down to the bottom of root <div#armor-info>
      // (the header's y position within the root, plus the header's own height)
      const hOffset = (header.value?.offsetTop ?? 0) + hHeight;

      isFolded.value = true;

      /**
       * @note
       *
       * 1. move off the top by the height of the entire root div
       * 2. move down by the height of the nav: the bottom edge is now even with
       *    nav
       * 3. move down by one more nav-height, now the exposed portion of the
       *    root div is the same size as the nav
       */

      foldedRootStyles.value = {
        top: `calc(-${rHeight}px + var(--nav-height) * 2)`
      };

      /**
       * @note
       *
       * 1. move the header down by the full height of the root
       *
       *        + rHeight
       *
       * 2. move the header back up by the size equal to (header height +
       *    distance from top of root). this puts the header sitting on the
       *    bottom edge of the root.
       *
       *        - hOffset
       *
       * 3. move the header back up to center between the nav and the bottom of
       *    the div:
       *
       *        - up by nav-height, now it's along the bottom edge of the nav
       *        + down by the height of the header; now, between the bottom edge
       *          of root and the bottom of header, there's what was previously
       *          the distance between the top of header and the bottom edge of
       *          nav
       *        finally, divide that whole calculation by two, since we want to
       *        *center* it.
       *
       * 4. final equation to move down by:
       *
       *        + (rHeight px - hOffset px)
       *        - (var(--nav-height) - hHeight px) / 2
       */

      const one = `${rHeight - hOffset}px`;
      const two = `(var(--nav-height) - ${hHeight}px)`;
      const all = `${one} - ${two} / 2`;

      foldedHeaderStyles.value = {
        // One final 0.1rem to account for border + text funkiness
        transform: `translateY(calc(${all} + 0.1rem))`
      };
    }
  });

  return {
    root, header, isFolded, foldedRootStyles, foldedHeaderStyles,
    unfold: () => { if (isFolded.value) unfold(); } // used for keypress/click
  };
}
