
import { defineComponent, ref, computed, PropType, toRefs } from 'vue';

export default defineComponent({
  props: {
    noButtons: {
      type: Boolean as PropType<boolean>, required: false, default: false
    },
    swapButtons: {
      type: Boolean as PropType<boolean>, required: false, default: false
    },
    containerClass: {
      type: String as PropType<string>, required: false
    }
  },
  emits: [ 'confirm', 'cancel' ],
  setup(props, { emit }) {
    const { noButtons, swapButtons: swap, containerClass } = toRefs(props);

    const refL = ref<HTMLButtonElement>();
    const refR = ref<HTMLButtonElement>();

    const classL = computed(() => !swap.value ? 'danger-button' : 'button');
    const classR = computed(() => !swap.value ? 'button' : 'danger-button');

    const slotL = computed(() => !swap.value ? 'submit' : 'cancel');
    const slotR = computed(() => !swap.value ? 'cancel' : 'submit');

    const nameL = computed(() => !swap.value ? 'Yes' : 'No');
    const nameR = computed(() => !swap.value ? 'No' : 'Yes');

    const clickL = computed(() => {
      return () => {
        emit(!swap.value ? 'confirm' : 'cancel');
        refL.value?.blur();
      }
    });

    const clickR = computed(() => {
      return () => {
        emit(!swap.value ? 'cancel' : 'confirm');
        refR.value?.blur();
      }
    });

    return {
      noButtons, containerClass,
      classL, classR, slotL, slotR, clickL, clickR, nameL, nameR
    };
  }
});
