
import { defineComponent, computed } from 'vue';
import store from '@/store';

import router from '@/router';

export default defineComponent({
  name: 'Cotera.io',
  setup() {
    const isSignedIn = computed(() => store.state.userID !== null);

    const showFooter = computed(() => {
      return (
        router.currentRoute.value.name == 'Home' ||
        router.currentRoute.value.name == 'About'
      );
    });

    const commit: string = process.env.VUE_APP_COMMIT;
    const semver: string = process.env.VUE_APP_SEMVER;

    const shortCommit = commit.substring(0, 7);
    const tree = `https://github.com/matthew-e-brown/cotera.io/tree/${commit}`;

    return { isSignedIn, showFooter, semver, shortCommit, tree };
  }
});
