
import { defineComponent, computed, toRefs, ref } from 'vue';

import TheArmorInfo from '@/components/TheArmorInfo.vue';
import ListPicker from '@/components/ListPicker.vue';
import ArmorItem from '@/components/ArmorItem.vue';

import ShirtIcon from '@/assets/icons/shirt.svg';
import AmiiboIcon from '@/assets/icons/amiibo.svg';

import store, { toggleSort } from '@/store';
import armor, { amiiboList as amiibo } from '@/armor';


export default defineComponent({
  name: 'Home',
  components: { TheArmorInfo, ListPicker, ArmorItem, ShirtIcon, AmiiboIcon },
  setup() {
    const { sortOrder, showAmiibo } = toRefs(store.state.settings);
    const popperBounds = ref<HTMLDivElement>();

    const capitalize = (str: string): string => {
      return `${str[0].toUpperCase()}${str.slice(1)}`;
    }

    const toggleSortState = () => {
      store.setSetting('sortOrder', toggleSort(sortOrder.value));
    }

    const toggleAmiibo = () => {
      store.setSetting('showAmiibo', !showAmiibo.value);
    }

    const sortLabel = computed(() => capitalize(sortOrder.value));
    const ariaSortLabel = computed(() => toggleSort(sortOrder.value));

    return {
      armor, amiibo,
      toggleSortState, toggleAmiibo, sortLabel, ariaSortLabel, showAmiibo,
      popperBounds
    };
  }
});
