
import {
  defineComponent, Ref, toRef, ref, computed, watch, nextTick, onMounted,
  onUnmounted, PropType
} from 'vue';

import { createPopper, Instance } from '@popperjs/core/lib/popper-lite';
import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow';
import eventListeners from '@popperjs/core/lib/modifiers/eventListeners';
import computeStyles from '@popperjs/core/lib/modifiers/computeStyles';
import offset from '@popperjs/core/lib/modifiers/offset';

import store from '@/store';
import { ListID, ListInfo } from '@/store/types';
import { defaults } from '@/store/helpers';

import ConfirmModal from '@/components/ConfirmModal.vue';


function usePopper(opened: Ref<boolean>, bounds: Ref<HTMLElement | undefined>) {

  let instance: Instance | null = null;
  const reference = ref<HTMLButtonElement>();
  const element = ref<HTMLUListElement>();
  const wrapper = ref<HTMLDivElement>();


  /**
   * Gets the padding from the computed styles of an element (in the most
   * convoluted way possible, instead of just grabbing the four properties; for
   * fun LOL)
   * @param el The element of which the styles should be parsed from
   * @returns An object with a property for each side, in pixels
   */
  const getPadding = (el?: HTMLElement) => {
    if (!el) return { top: 0, right: 0, bottom: 0, left: 0 };

    type SidesUpper = 'Top' | 'Right' | 'Bottom' | 'Left';
    type SidesLower = 'top' | 'right' | 'bottom' | 'left';

    const styles = getComputedStyle(el);

    // For each 'Side'...
    return [ 'Top', 'Right', 'Bottom', 'Left' ].reduce((acc, cur) => {
      const key = `padding${cur}` as `padding${SidesUpper}`;
      const val = parseFloat(styles[key]);

      // ... set 'side' = styles['paddingSide']
      acc[cur.toLowerCase() as SidesLower] = val;

      return acc;
    }, { } as { [ k in SidesLower ]: number });
  }


  /**
   * Gets the current font-size of an element in pixels, to be used as a
   * multiplier for 'ems'
   * @param el The element to get the font size of
   * @return The element's font size in pixels.
   */
  const getFontSize = (el?: HTMLElement) => {
    const styles = getComputedStyle(el ?? document.documentElement);
    return parseFloat(styles.fontSize);
  }


  /**
   * Closes the Popper. This event goes on the window itself.
   * @note We rely on the stopping of event propagation to stop this from firing
   * when we don't want it to.
   */
  const clickClose = () => void (opened.value = false);


  /**
   * Checks if the new focus target is within the wrapper, and closes the Popper
   * if it is not.
   */
  const wrapperFocusout = (event: FocusEvent) => {
    // let the 'click' handler handle things if we don't know what the new
    // target is:
    if (event.relatedTarget == null) return;

    // check if they transferred focus to something inside the wrapper
    else if (!wrapper.value?.contains(event.relatedTarget as Element)) {
      opened.value = false;
    }
  }


  /**
   * Generates the configuration for the Popper Instance. 
   * @note Needs to be generated every time because just "setting" the
   * 'eventListeners' to false option will make it the *only* "set" modifier,
   * causing popper to break upon re-opening.
   */
  const generateModifiers = (open: boolean) => {
    return [
      // We disable gpuAcceleration to force Popper to use 'inset' instead of
      // 'transform3d' to avoid the non-transformed "ghost" of the element from
      // expanding the viewport
      { ...computeStyles, options: { gpuAcceleration: false }},
      // preventOverflow
      { ...preventOverflow, enabled: true, options: {
        boundary: bounds.value ?? document.querySelector('#app'),
        padding: getPadding(bounds.value),
        rootBoundary: 'document'
      }},
      // offset
      { ...offset, enabled: true, options: {
        offset: [ 0, getFontSize(element.value) * 0.5 ]
      }},
      // eventListeners
      { ...eventListeners, enabled: open }
    ];
  }


  /**
   * Creates the Popper instance and adds the window event listener.
   */
  const mount = () => {
    if (!reference.value || !element.value)
      throw new Error("DOM refs to self somehow not established?");

    if (instance !== null) {
      instance.destroy();
      instance = null;
    }

    instance = createPopper(reference.value, element.value, {
      modifiers: generateModifiers(false),
      placement: 'bottom',
    });

    window.addEventListener('click', clickClose, { capture: false });
  }

  /**
   * Cleans up the popper instance and removes the window's close event
   * listener.
   */
  const unmount = () => {
    if (instance !== null) {
      instance.destroy();
      instance = null;
    }

    window.removeEventListener('click', close);
  }


  // Use watch instead of onMounted because the 'overflow-bounds' ref could
  // technically be reactive and change.
  watch(bounds, value => {
    if (value === undefined) {
      unmount();
    } else {
      // re-mount
      unmount();
      mount();
    }
  });


  // Update Popper position when opened
  watch(opened, async isOpen => {
    // toggle the modifiers depending on current state
    await instance?.setOptions({ modifiers: generateModifiers(isOpen) });
    if (isOpen) await instance?.update();
  });


  // Update popper's position if TheArmorInfo changes size (and popper is open).
  // This will happen when they have something selected and change list: when
  // the deselect happens, the dom is shunted upwards
  watch(toRef(store.state, 'selected'), () => {
    if (opened.value) instance?.update();
  });

  watch(toRef(store.state.settings, 'selectedList'), () => {
    if (opened.value) instance?.update();
  })

  onMounted(mount);
  onUnmounted(unmount);

  return {
    popperReference: reference,
    popperElement: element,
    wrapper, wrapperFocusout
  };
}


export default defineComponent({
  props: {
    overflowBounds: {
      type: Object as PropType<HTMLElement>,
      required: false
    }
  },
  components: { ConfirmModal },
  setup(props) {
    const opened = ref(false);

    const listInfo: Ref<ListInfo[]> = toRef(store.state, 'listInfo');

    const toRemove: Ref<ListInfo | null> = ref(null);

    const beingEdited: Ref<ListID | null> = ref(null);
    const editingTemp = ref("");

    const beingAdded: Ref<string | null> = ref(null);

    const editInput = ref<HTMLInputElement>();
    const addInput = ref<HTMLInputElement>();

    const edit = (info: ListInfo) => {
      // stop the add
      beingAdded.value = null;
      // open up the editing field
      beingEdited.value = info.id;
      editingTemp.value = info.name;
      // nextTick to wait until its v-if has processed, otherwise 'ref' is not
      // established
      nextTick(() => {
        // put the old name in the placeholder, so they can see it if they
        // deleted it (don't use a reactive placeholder, it doesn't need to
        // change)
        if (editInput.value) editInput.value.placeholder = info.name;
        // focus the input
        editInput.value?.focus();
      });
    }

    const add = () => {
      beingEdited.value = null;
      editingTemp.value = "";
      beingAdded.value = "";
      nextTick(() => addInput.value?.focus())
    }

    const stopEdit = () => {
      beingEdited.value = null;
      editingTemp.value = "";
    }

    const stopAdd = () => {
      beingAdded.value = null;
    }

    // Make it so they can't have an empty name and limit the length to 40 chars
    const isValid = (name: string) => name.length > 0 && name.length <= 40;

    const submitEdit = (id: ListID) => {
      if (beingEdited.value !== null && isValid(editingTemp.value)) {
        store.renameList(id, editingTemp.value);
        beingEdited.value = null;
        editingTemp.value = "";
      }
    }

    const submitAdd = () => {
      if (beingAdded.value !== null && isValid(beingAdded.value)) {
        store.addNewList(beingAdded.value);
        beingAdded.value = null;
      }
    }

    const moveDown = (index: number) => {
      if (index < store.state.listInfo.length - 1) {
        store.reorderListInfo(index, index + 1);
      }
    }

    const moveUp = (index: number) => {
      if (index > 0) {
        store.reorderListInfo(index, index - 1);
      }
    }

    const select = (id: ListID) => {
      if (id != store.state.settings.selectedList)
        store.setSetting('selectedList', id);
    }

    const remove = (id: ListID) => {
      if (id != store.state.settings.selectedList) {
        store.removeList(id);
        toRemove.value = null;
      }
    }

    const selected = computed(() => {
      const sel = toRef(store.state.settings, 'selectedList');

      // Use ?? because there's a chance that 'settings.selectedList' updates
      // from Firestore before the 'state.listInfo' array is downloaded --
      // meaning we won't have the list names.
      return store.state.listInfo.find(({ id }) => sel.value == id)
        ?? defaults.settings().selectedList;
    });

    return {
      listInfo, selected, opened, isValid,
      edit, beingEdited, editingTemp, stopEdit, submitEdit, editInput,
      add, beingAdded, stopAdd, submitAdd, addInput,
      moveDown, moveUp, select, toRemove, remove,
      ...usePopper(opened, toRef(props, 'overflowBounds')),
    };
  }
});
