
import { defineComponent, computed, ref, toRef } from 'vue';

import { Armor } from '@/armor';
import store from '@/store';

export default defineComponent({
  name: 'ArmorItem',
  props: {
    armor: { type: Armor, required: true }
  },
  setup(props) {
    // Use setup() function to get better TypeScript on button ref
    const button = ref<HTMLButtonElement>();

    const current = toRef(store.state, 'selected');
    const selected = computed(() => {
      if (current.value === null) return false;
      else if (current.value.tag === props.armor.tag) return true;
      else return false;
    });

    const select = (): void => {
      // If currently selected, deselect; otherwise select
      store.setSelected(selected.value ? null : props.armor);
    }

    return { select, selected, button };
  }
});
